import React from "react";
import styled from "@emotion/styled";
import { VectorMap } from "react-jvectormap";
import { CardContent, Card as MuiCard, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import { GenericObject } from "../../../data/dashboard-api";
import { grey } from "@mui/material/colors";

const MapContainer = styled.div`
  height: 300px;
`;

const Card = styled(MuiCard)(spacing);
type StatsProps = {
  data: GenericObject;
};

const USA: React.FC<StatsProps> = ({ data }) => {
  let finalData: any = {};
  for (let key in data) {
    const okey = key.toLocaleLowerCase().trimStart().trimEnd();
    switch (okey) {
      case "veteran's affairs":
      case "va":
        finalData["US-VA"] = finalData.hasOwnProperty("US-VA")
          ? finalData["US-VA"] + data[key]
          : 0 + data[key];
        break;
      case "oh":
      case "ohio":
        finalData["US-OH"] = finalData.hasOwnProperty("US-OH")
          ? finalData["US-OH"] + data[key]
          : 0 + data[key];
        break;
      case "az":
        finalData["US-AZ"] = finalData.hasOwnProperty("US-AZ")
          ? finalData["US-AZ"] + data[key]
          : 0 + data[key];
        break;
      case "al":
        finalData["US-AL"] = finalData.hasOwnProperty("US-AL")
          ? finalData["US-AL"] + data[key]
          : 0 + data[key];
        break;
      case "ar":
        finalData["US-AR"] = finalData.hasOwnProperty("US-AR")
          ? finalData["US-AR"] + data[key]
          : 0 + data[key];
        break;
      case "ca":
        finalData["US-CA"] = finalData.hasOwnProperty("US-CA")
          ? finalData["US-CA"] + data[key]
          : 0 + data[key];
        break;

      case "ct":
        finalData["US-CT"] = finalData.hasOwnProperty("US-CT")
          ? finalData["US-CT"] + data[key]
          : 0 + data[key];
        break;
      case "dc":
        finalData["US-DC"] = finalData.hasOwnProperty("US-DC")
          ? finalData["US-DC"] + data[key]
          : 0 + data[key];
        break;
      case "fl":
        finalData["US-FL"] = finalData.hasOwnProperty("US-FL")
          ? finalData["US-FL"] + data[key]
          : 0 + data[key];
        break;
      case "ga":
        finalData["US-GA"] = finalData.hasOwnProperty("US-GA")
          ? finalData["US-GA"] + data[key]
          : 0 + data[key];
        break;
      case "il":
        finalData["US-IL"] = finalData.hasOwnProperty("US-IL")
          ? finalData["US-IL"] + data[key]
          : 0 + data[key];
        break;
      case "nc":
        finalData["US-NC"] = finalData.hasOwnProperty("US-NC")
          ? finalData["US-NC"] + data[key]
          : 0 + data[key];
        break;
      case "ny":
        finalData["US-NY"] = finalData.hasOwnProperty("US-NY")
          ? finalData["US-NY"] + data[key]
          : 0 + data[key];
        break;
      case "pa":
        finalData["US-PA"] = finalData.hasOwnProperty("US-PA")
          ? finalData["US-PA"] + data[key]
          : 0 + data[key];
        break;
      case "ut":
        finalData["US-UT"] = finalData.hasOwnProperty("US-UT")
          ? finalData["US-UT"] + data[key]
          : 0 + data[key];
        break;
      case "wi":
        finalData["US-WI"] = finalData.hasOwnProperty("US-WI")
          ? finalData["US-WI"] + data[key]
          : 0 + data[key];
        break;
      case "null":
        finalData["UNDEFINED"] = finalData.hasOwnProperty("UNDEFINED")
          ? finalData["UNDEFINED"] + data[key]
          : 0 + data[key];
        break;
      default:
        if (key.length === 2) {
          finalData["US-" + key.toUpperCase()] = data[key];
        } else {
          finalData["UNDEFINED"] = finalData.hasOwnProperty("UNDEFINED")
            ? finalData["UNDEFINED"] + data[key]
            : 0 + data[key];
        }
        break;
    }
  }

  let max = 0;
  for (const key in finalData) {
    if (Object.prototype.hasOwnProperty.call(finalData, key)) {
      const element = finalData[key];
      if (element > max) {
        max = element;
      }
    }
  }
  const getLegendNumbers = () => {
    const stepper = Math.ceil((0 + max) / 4);
    return [stepper, stepper * 2, stepper * 3, stepper * 4];
  };
  const options = {
    map: "us_aea",
    backgroundColor: "transparent",
    regionStyle: {
      initial: {
        fill: grey[300],
      },
    },
    series: {
      markers: [
        {
          attribute: "fill",
          scale: ["#C8EEFF", "#0071A4"],
          normalizeFunction: "polynomial",
          values:
            getLegendNumbers().length > 0
              ? getLegendNumbers()
              : [0, 100, 200, 300, 400],
          legend: {
            vertical: true,
          },
        },
      ],
      regions: [
        {
          values: finalData,
          scale: ["#C8EEFF", "#0071A4"],
          normalizeFunction: "polynomial",
        },
      ],
    },
    onRegionTipShow: function (e: any, el: any, code: string) {
      el.html(el.html() + `(Sites - ${finalData[code] || "0"})`);
    },
    containerStyle: {
      width: "100%",
      height: "100%",
    },
    zoomOnScroll: false,
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          USA Map
        </Typography>
        <MapContainer>
          <VectorMap {...options} />
        </MapContainer>
      </CardContent>
    </Card>
  );
};

export default USA;
