import React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

import { CardContent, Card as MuiCard, Typography, Theme } from "@mui/material";
import { spacing } from "@mui/system";

// import { ThemeProps } from "../../../types/theme";
import { TheaupaticResponse } from "../../../data/dashboard-api";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  height: 350px;
  width: 100%;
`;

type StatsProps = {
  theme: Theme & {
    palette: any;
  };
  theaupaticCount: TheaupaticResponse[];
};

const PieChart: React.FC<StatsProps> = ({ theme, theaupaticCount }) => {
  const other = {
    _id: "Others",
    count: 0,
  };

  let finalData = theaupaticCount
    // eslint-disable-next-line array-callback-return
    .map((m, index) => {
      if (index < 5) {
        return m;
      } else {
        other.count += m.count;
      }
    })
    .filter((f) => f);
  if (other.count > 0) {
    finalData = finalData.concat(other);
  }
  const labels = finalData.map((m: any) => m._id);
  const chartData = finalData.map((m: any) => m.count);
  const data = chartData;

  const options: ApexOptions = {
    dataLabels: {
      enabled: false,
    },
    labels: labels as string[],
    colors: ["#008ffb", "#00e396", "#ffb01a", "#ff4560", "#775dd0", "#a3d386"],
  };

  return (
    <Card mb={1}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Therapeutic Specialties
        </Typography>

        <Spacer mb={6} />

        <ChartWrapper>
          <Chart options={options} series={data} type="donut" height="350" />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default withTheme(PieChart);
