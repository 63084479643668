import axios from "axios";

const baseUrl = `${process.env.REACT_APP_BASE_URL}/dashboard`;
/* TODO: From the configuration */

export interface UsersCount {
  doctor: number;
  sponsor: number;
  admin: number;
  consultant: number;
  internaluser: number;
}

export interface TheaupaticResponse {
  _id: null | string;
  count: number;
}

export interface TrialsCounts {
  totalTrials: number;
  activeTrials: number;
}

export interface TrialPhase {
  sitetrials: Sitetrial[];
}

export interface Sitetrial {
  status: string;
  interested: string;
  cdaReleased?: boolean;
  cdaSponsorApproved?: boolean;
  accepted?: boolean;
  psvProposedDates?: object;
  psvVisitedDate?: string;
  siteApproved?: boolean;
  ctaStatus?: number;
  regulatoryDocs?: {
    form1572: {
      approved?: boolean;
    };
  };
  irbPackageSubmitted?: string;
  irbApproved?: any;
  irbFiles: {
    approvalLetter?: {
      approved?: boolean;
    };
    membershipList?: {
      approved?: boolean;
    };
  };
  contact_status?: object;
  willJoinIM?: number;
  edcSupport?: boolean;
  ivrsSupport?: boolean;
  drugShipmentRequestDate?: any;
  drugShipmentSentDate?: string;
  drugShipmentRecievedDate?: string;
  sivProposedDates?: object;
  cvsApproved?: number;
  fqAnswers?: Array<string>;
  fqApproved?: boolean;
  siqApproved?: boolean;
  ctaSponsorApproved?: boolean;
  budgetSponsorApproved?: boolean;
  sivVisitedDate?: null | string;
  completed?: boolean;
  trialId: string;
}

export interface GenericObject {
  [key: string]: any;
}

export const getDifferentUserCounts = async (
  trialId?: string
): Promise<UsersCount> => {
  let url = `${baseUrl}/users/count`;
  if (trialId) {
    url += `?trialId=${trialId}`;
  }
  const response = await axios.get<UsersCount>(url);
  return response.data;
};

export const getTheaupaticCounts = async (
  trialId?: string
): Promise<TheaupaticResponse[]> => {
  let url = `${baseUrl}/users/theaupatic`;
  if (trialId) {
    url += `?trialId=${trialId}`;
  }
  const response = await axios.get<TheaupaticResponse[]>(url);
  return response.data;
};

export const getTrialsCounts = async (
  trialId?: string
): Promise<TrialsCounts> => {
  let url = `${baseUrl}/trials/count`;
  if (trialId) {
    url += `?trialId=${trialId}`;
  }
  const response = await axios.get<TrialsCounts>(url);
  return response.data;
};

export const getTrialPhases = async (
  trialId?: string
): Promise<TrialPhase[]> => {
  let url = `${baseUrl}/trials/phases`;
  if (trialId) {
    url += `?trialId=${trialId}`;
  }
  const response = await axios.get<TrialPhase[]>(url);
  return response.data;
};

export const getWorldMapData = async (
  trialId?: string
): Promise<GenericObject> => {
  let url = `${baseUrl}/users/locations`;
  if (trialId) {
    url += `?trialId=${trialId}`;
  }
  const response: any = await axios.get<GenericObject>(url);
  return response.data;
};

export const getUsersTrials = async (
  userId?: string
): Promise<GenericObject> => {
  let url = `${baseUrl}/users/trials`;
  if (userId) {
    url += `?userId=${userId}`;
  }
  const response: any = await axios.get<GenericObject>(url);
  return response.data;
};

export const getUSAStatesMapData = async (
  trialId?: string
): Promise<GenericObject> => {
  let url = `${baseUrl}/users/us-states`;
  if (trialId) {
    url += `?trialId=${trialId}`;
  }
  const response: any = await axios.get<GenericObject>(url);
  return response.data;
};
