import React from "react";
import styled from "@emotion/styled";
import { Theme, withTheme } from "@emotion/react";
import { VectorMap } from "react-jvectormap";

import { CardContent, Card as MuiCard, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import { GenericObject } from "../../../data/dashboard-api";
import { grey } from "@mui/material/colors";
import "../../../vendor/jvectormap.css";

const MapContainer = styled.div`
  height: 300px;
`;

const Card = styled(MuiCard)(spacing);
type StatsProps = {
  theme: Theme & {
    palette: any;
  };
  data: GenericObject;
};

const World: React.FC<StatsProps> = ({ theme, data }) => {
  let finalData: any = {};
  for (let key in data) {
    const okey = key.toLocaleLowerCase().trimStart().trimEnd();
    switch (okey) {
      case "usa":
      case "united states":
      case "us":
        finalData["US"] = finalData.hasOwnProperty("US")
          ? finalData["US"] + data[key]
          : 0 + data[key];
        break;
      case "puerto rico":
      case "pr":
        finalData["PR"] = finalData.hasOwnProperty("PR")
          ? finalData["PR"] + data[key]
          : 0 + data[key];
        break;
      case "canada":
      case "ca":
      case "can":
        finalData["CA"] = finalData.hasOwnProperty("CA")
          ? finalData["CA"] + data[key]
          : 0 + data[key];
        break;
      case "ind":
      case "india":
        finalData["IN"] = finalData.hasOwnProperty("IN")
          ? finalData["IN"] + data[key]
          : 0 + data[key];
        break;
      case "jo":
      case "jordan":
        finalData["JO"] = finalData.hasOwnProperty("JO")
          ? finalData["JO"] + data[key]
          : 0 + data[key];
        break;
      case "mexico":
      case "mex":
        finalData["MX"] = finalData.hasOwnProperty("MX")
          ? finalData["MX"] + data[key]
          : 0 + data[key];
        break;
      case "bra":
      case "Brazil":
        finalData["BZ"] = finalData.hasOwnProperty("BZ")
          ? finalData["BZ"] + data[key]
          : 0 + data[key];
        break;
      case "abu dhabi":
      case "are":
      case "ae":
        finalData["AE"] = finalData.hasOwnProperty("AE")
          ? finalData["AE"] + data[key]
          : 0 + data[key];
        break;
      case "pl":
      case "pol":
      case "poland":
        finalData["PL"] = finalData.hasOwnProperty("PL")
          ? finalData["PL"] + data[key]
          : 0 + data[key];
        break;
      case "cze":
        finalData["XC"] = finalData.hasOwnProperty("XC")
          ? finalData["XC"] + data[key]
          : 0 + data[key];
        break;
      case "ita":
        finalData["XC"] = finalData.hasOwnProperty("XC")
          ? finalData["XC"] + data[key]
          : 0 + data[key];
        break;
      default:
        if (key.length === 2) {
          finalData[key.toUpperCase()] = data[key];
        } else {
          finalData["UNDEFINED"] = finalData.hasOwnProperty("UNDEFINED")
            ? finalData["UNDEFINED"] + data[key]
            : 0 + data[key];
        }
        break;
    }
  }
  const options = {
    map: "world_mill",
    series: {
      regions: [
        {
          values: finalData,
          scale: ["#C8EEFF", "#0071A4"],
          normalizeFunction: "polynomial",
        },
      ],
    },
    onRegionTipShow: function (e: any, el: any, code: string) {
      el.html(el.html() + `(Sites - ${finalData[code] || "0"})`);
    },
    regionStyle: {
      initial: {
        fill: grey[300],
      },
    },
    backgroundColor: "transparent",
    containerStyle: {
      width: "100%",
      height: "100%",
    },
    zoomOnScroll: false,
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          World Map
        </Typography>
        <MapContainer>
          <VectorMap {...options} />
        </MapContainer>
      </CardContent>
    </Card>
  );
};

export default withTheme(World);
