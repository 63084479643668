import React from "react";
import styled from "@emotion/styled";
import { Theme, withTheme } from "@emotion/react";
import { TrialPhase } from "../../../data/dashboard-api";

import { Card as MuiCard, CardContent, CardHeader } from "@mui/material";
import { spacing } from "@mui/system";
import { ResponsiveFunnel } from "@nivo/funnel";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 340px;
  width: 100%;
`;

type StatsProps = {
  theme: Theme & {
    palette: any;
  };
  trialPhases: TrialPhase[];
  trialId: string | undefined;
};

const FunnelChart: React.FC<StatsProps> = ({ theme, trialPhases, trialId }) => {
  const processedData = {
    "Site Identification": 0,
    "CDA Released": 0,
    "CDA Approved": 0,
    "Trial Accepted": 0,
    "Feasiblity Completed": 0,
    "Feasiblity Approved": 0,
    "CVs Uploaded": 0,
    "SIQ Completed": 0,
    "PSV Scheduled": 0,
    "PSV Completed": 0,
    "Site Selected": 0,
    "CTA Completed": 0,
    "Budget Completed": 0,
    "RegulatoryDocs Uploaded": 0,
    "IRB Package Submitted": 0,
    "IRB Approval Letter Approved": 0,
    "IM Meeting Accepted": 0,
    "IVRS Approved": 0,
    "EDC Approved": 0,
    "Drug Received": 0,
    "SIV Scheduled": 0,
    "SIV Completed": 0,
    // eslint-disable-next-line prettier/prettier
    "Activated": 0,
  };

  trialPhases.forEach((e) => {
    e.sitetrials.forEach((trial) => {
      if (trial.trialId === trialId) {
        processedData["Site Identification"]++;
        if (trial.cdaReleased) {
          processedData["CDA Released"]++;
          if (trial.cdaSponsorApproved) {
            processedData["CDA Approved"]++;
            if (trial.accepted) {
              processedData["Trial Accepted"]++;
              if (trial.fqAnswers) {
                processedData["Feasiblity Completed"]++;
                if (trial.fqApproved) {
                  processedData["Feasiblity Approved"]++;
                  if (trial.cvsApproved === 2) {
                    processedData["CVs Uploaded"]++;
                    if (trial.siqApproved) {
                      processedData["SIQ Completed"]++;
                    }
                    if (trial.psvProposedDates) {
                      processedData["PSV Scheduled"]++;
                      if (trial.psvVisitedDate) {
                        processedData["PSV Completed"]++;
                        if (trial.siteApproved) {
                          processedData["Site Selected"]++;
                          if (trial.ctaSponsorApproved) {
                            processedData["CTA Completed"]++;
                            if (trial.budgetSponsorApproved) {
                              processedData["Budget Completed"]++;
                            }
                            // eslint-disable-next-line prettier/prettier
                            if (trial.regulatoryDocs && trial.regulatoryDocs['form1572'] && trial.regulatoryDocs['form1572']['approved']) {
                              processedData["RegulatoryDocs Uploaded"]++;
                              if (trial.irbPackageSubmitted) {
                                processedData["IRB Package Submitted"]++;
                                if (
                                  trial.irbFiles &&
                                  trial.irbFiles["approvalLetter"]
                                ) {
                                  // eslint-disable-next-line prettier/prettier
                                  processedData["IRB Approval Letter Approved"]++;
                                  if (trial.willJoinIM === 2) {
                                    processedData["IM Meeting Accepted"]++;
                                    if (trial.edcSupport) {
                                      processedData["EDC Approved"]++;
                                      if (trial.ivrsSupport) {
                                        processedData["IVRS Approved"]++;
                                        if (trial.drugShipmentRecievedDate) {
                                          processedData["Drug Received"]++;
                                          if (trial.sivProposedDates) {
                                            processedData["SIV Scheduled"]++;
                                            if (trial.sivVisitedDate) {
                                              processedData["SIV Completed"]++;
                                              if (trial.completed) {
                                                processedData["Activated"]++;
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } else if (!trialId) {
        // if (trial.interested) {
        processedData["Site Identification"]++;
        if (trial.cdaReleased) {
          processedData["CDA Released"]++;
          if (trial.cdaSponsorApproved) {
            processedData["CDA Approved"]++;
            if (trial.accepted) {
              processedData["Trial Accepted"]++;
              if (trial.fqAnswers) {
                processedData["Feasiblity Completed"]++;
                if (trial.fqApproved) {
                  processedData["Feasiblity Approved"]++;
                  if (trial.cvsApproved === 2) {
                    processedData["CVs Uploaded"]++;
                    if (trial.siqApproved) {
                      processedData["SIQ Completed"]++;
                    }
                    if (trial.psvProposedDates) {
                      processedData["PSV Scheduled"]++;
                      if (trial.psvVisitedDate) {
                        processedData["PSV Completed"]++;
                        if (trial.siteApproved) {
                          processedData["Site Selected"]++;
                          if (trial.ctaSponsorApproved) {
                            processedData["CTA Completed"]++;
                            if (trial.budgetSponsorApproved) {
                              processedData["Budget Completed"]++;
                            }
                            // eslint-disable-next-line prettier/prettier
                              if (trial.regulatoryDocs && trial.regulatoryDocs['form1572'] && trial.regulatoryDocs['form1572']['approved']) {
                              processedData["RegulatoryDocs Uploaded"]++;
                              if (trial.irbPackageSubmitted) {
                                processedData["IRB Package Submitted"]++;
                                if (
                                  trial.irbFiles &&
                                  trial.irbFiles["approvalLetter"]
                                ) {
                                  // eslint-disable-next-line prettier/prettier
                                    processedData["IRB Approval Letter Approved"]++;
                                  if (trial.willJoinIM === 2) {
                                    processedData["IM Meeting Accepted"]++;
                                    if (trial.edcSupport) {
                                      processedData["EDC Approved"]++;
                                      if (trial.ivrsSupport) {
                                        processedData["IVRS Approved"]++;
                                        if (trial.drugShipmentRecievedDate) {
                                          processedData["Drug Received"]++;
                                          if (trial.sivProposedDates) {
                                            processedData["SIV Scheduled"]++;
                                            if (trial.sivVisitedDate) {
                                              processedData["SIV Completed"]++;
                                              if (trial.completed) {
                                                console.log("trial: ", trial);
                                                processedData["Activated"]++;
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        // }
      }
    });
  });

  let finalData = [];
  for (const key in processedData) {
    if (Object.prototype.hasOwnProperty.call(processedData, key)) {
      const element = (processedData as any)[key];
      if (element) {
        finalData.push({
          id: key.toLocaleLowerCase().replaceAll(" ", "_"),
          // value: element + (previousElement ? previousElement : 0),
          value: element,
          label: key,
        });
      }
      // previousElement += element;
    }
  }

  return (
    <Card mb={6}>
      <CardHeader title="Total Trial Current Phase" />
      <CardContent>
        <ChartWrapper>
          {finalData.length > 0 && (
            <ResponsiveFunnel
              data={finalData}
              animate={false}
              colors={[
                "#D6EDFF",
                "#D6EDFF",
                "#C2E4FF",
                "#C2E4FF",
                "#ADDCFF",
                "#ADDCFF",
                "#99D3FF",
                "#99D3FF",
                "#70C1FF",
                "#70C1FF",
                "#5CB8FF",
                "#5CB8FF",
                "#33A7FF",
                "#33A7FF",
                "#1F9EFF",
                "#1F9EFF",
                "#0A95FF",
                "#0A95FF",
                "#85CAFF",
                "#85CAFF",
              ]}
              interpolation={"linear"}
              shapeBlending={0}
              borderWidth={1}
              labelColor={{
                from: "color",
                modifiers: [["darker", 3]],
              }}
              currentPartSizeExtension={2}
              afterSeparatorLength={100}
              beforeSeparatorLength={100}
              // currentBorderWidth={40}
              motionConfig="default"
            />
          )}
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default withTheme(FunnelChart);
