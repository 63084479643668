import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Box,
} from "@mui/material";
import { spacing } from "@mui/system";
import Stats from "./Stats";
import {
  getDifferentUserCounts,
  getTheaupaticCounts,
  getTrialsCounts,
  TheaupaticResponse,
  TrialsCounts,
  UsersCount,
  getTrialPhases,
  TrialPhase,
  getWorldMapData,
  GenericObject,
  getUSAStatesMapData,
  getUsersTrials,
} from "../../../data/dashboard-api";
import PieChart from "./PieChart";
import World from "./World";
import USA from "./USA";
import useAuth from "../../../hooks/useAuth";
import FunnelChart from "./FunnelChart";
import { Close } from "@mui/icons-material";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

function Default() {
  // const { t } = useTranslation();
  const [trialId, setSelectedTrialId] = useState<string>("");
  const [trialPhases, setTrialPhases] = useState<TrialPhase[]>([]);
  const [vectorData, setVectorData] = useState<GenericObject>({});
  const [stateData, setStateData] = useState<GenericObject>({});
  const { isAuthenticated, isInitialized, user } = useAuth();
  const [trialsSelect, setTrialsSelect] = useState<GenericObject>([]);
  const [selectedTrial, setSelectedTrial] = useState<string>("");

  const [userCounts, setUsersCounts] = useState<UsersCount>({
    doctor: 0,
    sponsor: 0,
    admin: 0,
    consultant: 0,
    internaluser: 0,
  });

  const [theaupaticCount, setTheaupaticCount] = useState<TheaupaticResponse[]>(
    []
  );

  const [trialCount, setTrialCount] = useState<TrialsCounts>({
    activeTrials: 0,
    totalTrials: 0,
  });

  const [telephonic, setTelephonic] = useState<any>({});

  const isAdmin = () => {
    return user?.scope === "admin" ? true : false;
  };

  useEffect(() => {
    if (user?.scope === "admin") {
      fetchAllData();
      fetchUsersTrials();
    } else {
      if (user?.id) {
        if (user.scope === "internaluser") {
          fetchUsersTrials(user?.belongsToSponsor);
        } else {
          fetchUsersTrials(user?.id);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, user?.scope]);

  const fetchAllData = async (trialId?: string) => {
    fetchDashboardUsersCount(trialId);
    fetchDashboardTheaupaticData(trialId);
    fetchDashboardTrialsPhases(trialId);
    fetchVectorMapData(trialId);
    fetchUSAStateData(trialId);
    fetchDashboardtrialsCount(trialId);
  };

  const fetchUsersTrials = async (userId?: string) => {
    try {
      const data = await getUsersTrials(userId);
      setTrialsSelect(data);
    } catch (error) {
      console.error("Here", error);
    }
  };

  const fetchUSAStateData = async (trialId?: string) => {
    try {
      const data = await getUSAStatesMapData(trialId);
      setStateData(data);
    } catch (error) {
      console.error("Here", error);
    }
  };

  const fetchVectorMapData = async (trialId?: string) => {
    try {
      const data = await getWorldMapData(trialId);
      setVectorData(data);
    } catch (error) {
      console.error("Here", error);
    }
  };

  const fetchDashboardtrialsCount = async (trialId?: string) => {
    try {
      const data = await getTrialsCounts(trialId);
      setTrialCount(data);
    } catch (error) {
      console.error("Here", error);
    }
  };

  const fetchDashboardTheaupaticData = async (trialId?: string) => {
    try {
      const data = await getTheaupaticCounts(trialId);
      setTheaupaticCount(data);
    } catch (error) {
      console.error("Here", error);
    }
  };

  const fetchDashboardTrialsPhases = async (trialId?: string) => {
    try {
      let selectedTrialId: string | undefined;
      if (trialId) {
        selectedTrialId = trialId;
        setSelectedTrialId(selectedTrialId);
      }
      const data = await getTrialPhases(selectedTrialId);
      setTrialPhases(data);
      let telephonicData: any = [];
      data.forEach((e) => {
        (e.sitetrials || []).forEach((ei) => {
          if (ei.contact_status) {
            // telephonicData.push(...(ei.contact_status as string[]));
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            trialId
              ? ei.trialId === trialId
                ? telephonicData.push(...(ei.contact_status as string[]))
                : null
              : telephonicData.push(...(ei.contact_status as string[]));
          }
        });
      });

      let telephonicDataObj: any = {};
      telephonicData.forEach((e: string) =>
        telephonicDataObj[e]
          ? telephonicDataObj[e]++
          : (telephonicDataObj[e] = 1)
      );

      setTelephonic(telephonicDataObj);
    } catch (error) {
      console.error("Here", error);
    }
  };

  const fetchDashboardUsersCount = async (trialId?: string) => {
    try {
      const data = await getDifferentUserCounts(trialId);
      setUsersCounts(data);
    } catch (error) {
      console.error("Here", error);
    }
  };

  const removeSelectedItem = async () => {
    setSelectedTrial("");
    fetchAllData();
  };

  const onChangeTrial = async (e: any) => {
    const id = e.target.value;
    setSelectedTrial(id);
    fetchAllData(id);
  };

  if (isInitialized && !isAuthenticated) {
    return (
      <React.Fragment>
        <Helmet title="Dashboard" />
        <Grid justifyContent="space-between" container spacing={6}>
          <Grid item>
            <Typography variant="h3" gutterBottom>
              Dashboard View
            </Typography>
          </Grid>
        </Grid>
        <Divider my={6} />
        <Typography variant="h3" gutterBottom>
          Access Not Allowed
        </Typography>
      </React.Fragment>
    );
  }

  if (isInitialized && isAuthenticated && !isAdmin() && !selectedTrial) {
    return (
      <React.Fragment>
        <Helmet title="Dashboard" />
        <Grid justifyContent="space-between" container spacing={6}>
          <Grid item>
            <Typography variant="h3" gutterBottom>
              Dashboard View
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography variant="h3" gutterBottom>
              <FormControl fullWidth>
                <InputLabel>Select Trial</InputLabel>
                <Select
                  value={selectedTrial}
                  label="Select Trial"
                  onChange={(e) => onChangeTrial(e)}
                >
                  {trialsSelect.map((m: any, index: any) => (
                    <MenuItem key={index} value={m.id}>
                      {m.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Typography>
          </Grid>
        </Grid>
        <Divider my={6} />
        <Typography variant="h3" gutterBottom>
          Please Select Trial To begin
        </Typography>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Dashboard View
          </Typography>
        </Grid>
        <Grid item md={3}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <FormControl fullWidth>
              <InputLabel>Select Trial</InputLabel>
              <Select
                value={selectedTrial}
                label="Select Trial"
                onChange={(e) => onChangeTrial(e)}
              >
                {trialsSelect.map((m: any, index: any) => (
                  <MenuItem key={index} value={m.id}>
                    {m.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedTrial !== "" && (
              <IconButton
                onClick={() => removeSelectedItem()}
                aria-label="delete"
                size="medium"
              >
                <Close fontSize="inherit" />
              </IconButton>
            )}
          </Box>
        </Grid>
      </Grid>

      <Divider my={6} />
      {isAdmin() && (
        <React.Fragment>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12} md={6} lg={3} xl>
              <Stats
                title="Total Trials"
                amount={(trialCount?.totalTrials || 0).toString()}
                chip="TOTAL TRIAL"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl>
              <Stats
                title="Total Active Trials"
                amount={(trialCount?.activeTrials || 0).toString()}
                chip="ACTIVE TRIAL"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl></Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl></Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12} md={6} lg={3} xl>
              <Stats
                title="Total Admins"
                amount={(userCounts?.admin || 0).toString()}
                chip="ADMIN"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl>
              <Stats
                title="Total Sites"
                amount={(userCounts?.doctor || 0).toString()}
                chip="SITE"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl>
              <Stats
                title="Total Sponsors"
                amount={(userCounts?.sponsor || 0).toString()}
                chip="SPONSOR"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl>
              <Stats
                title="Total External Users"
                amount={(userCounts?.consultant || 0).toString()}
                chip="EXTERNAL"
              />
            </Grid>
          </Grid>
        </React.Fragment>
      )}
      <Divider my={6} />
      <React.Fragment>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={6} lg={3} xl>
            <Stats
              title="Emails"
              amount={(telephonic["email"] || 0).toString()}
              chip="Email"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl>
            <Stats
              title="Call Complete"
              amount={(telephonic["call complete"] || 0).toString()}
              chip="CALL COMPLETE"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl>
            <Stats
              title="Voicemail"
              amount={(telephonic["voicemail"] || 0).toString()}
              chip="VOICEMAIL"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl>
            <Stats
              title="Non Working"
              amount={(telephonic["non-working"] || 0).toString()}
              chip="NON WORKING"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl>
            <Stats
              title="Callback"
              amount={(telephonic["call back"] || 0).toString()}
              chip="CALL BACK"
            />
          </Grid>
        </Grid>
      </React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={6}>
          <PieChart theaupaticCount={theaupaticCount}></PieChart>
        </Grid>
        <Grid item xs={12} lg={6}>
          <FunnelChart trialPhases={trialPhases} trialId={trialId} />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <World data={vectorData} />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <USA data={stateData} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Default;
